@use "../../styles/global" as *;
@use "../../styles/partials/variables" as *;

.venue-request {
  @extend .general-tab;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1vw 2vw 1vw 1vw;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 2.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    gap: 8px;

    padding: 0 1vw;

  }
}
