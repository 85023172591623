@use "../../styles/partials/variables" as *;

.topnav {
  height: var(--topbar-height);
  width: 100%;
  position: fixed;

  z-index: 999;

  background: linear-gradient(181.5deg, #2C204E 5%, #010202 100%, #0D0024 10%);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 24px;
  padding-right: 24px;

  &__logo {
    height: auto;
    max-width: 120px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 50%;
  }

  &__signout {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba($white, 0.2);
    border-radius: 20px;

    width: max-content;
    padding: 0.8vw 1vw;

    position: absolute;
    right: 24px;
    top: calc(var(--topbar-height) * 0.75);
    z-index: 2;

    cursor: pointer;

    gap: 10px;
    
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &-left {
      display: flex;
      align-items: center;

      &-text {
        margin-left: 0.3vw;

        color: #fff;
      }
    }
  }
}
