.contracts-textarea {
  background-color: transparent;
  border: none;

  color: #fff;

  font-weight: 400;

  font-size: 14px;

  width: 100%;

  height: 70%;

  resize: none;
}

.contracts-textarea::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.contracts-textarea::-webkit-scrollbar-track {
  background: #444;
}

.contracts-textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.contracts-textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.button-terms {
  margin-top: 20px;

  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  padding: 16px 32px 16px 32px;
  color: #fff;
  font-size: 18px;

  background: linear-gradient(
    271.14deg,
    rgb(114, 41, 245) 1.5%,
    rgb(10, 63, 214) 98.6%
  );
  cursor: pointer;
}
