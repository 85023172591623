
.bottomTabs{
    list-style: none;
    margin: 0;
    padding: 0.5rem 0.5rem;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: linear-gradient(to left, #0c0024 50%, #0c0024 100%, #0c0024 100%);

    display: flex;
    align-items: stretch;
    justify-content: stretch;
    z-index: 18;
}

.bottomTabs > *{
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    width: 100%;

}

.bottomTabs .tab-item{
    all: unset;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 2px;

    justify-content: center;
    text-align: center;

    font-size: 14px;

    padding: 0.5rem 0;
}
.bottomTabs .tab-item .icon{
    font-size: 20px;
}