@use "../../styles/global" as *;
@use "../../styles/partials/variables" as *;

.edit-ad {
  @extend .general-tab;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1vw 2vw 1vw 1vw;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 2.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

    &-button {
      display: flex;
      align-items: center;

      background-color: transparent;
      color: $white;
      font-size: 1.3rem;

      border-radius: 8px;
      border: 2px solid $white;

      padding: 1vw 2vw;
    }
  }

  &__form {
    width: 100%;

    display: flex;

    &--left {
      width: 60%;
    }

    &--right {
      padding: 2vw 0 0 6vw;
    }

    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      &-field {
        display: flex;
        flex-direction: column;

        width: 45%;

        margin-bottom: 2vw;

        color: $white;

        &-label {
          padding: 0.5vw 2vw;

          &--extra {
            padding: 0vw 1vw 0.5vw 1vw;
          }
        }

        &-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &--calendar {
            margin-left: 2vw;

            &:hover {
              cursor: pointer;
            }
          }
        }

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;
          width: 100%;

          &:focus {
            border: 1px solid $general-purple;
          }

          &--special {
            display: flex;
            justify-content: space-between;
            padding-right: 1vw;

            // width: 90%;
          }

          &-text {
            font-size: 0.8rem;
            font-weight: 300;

            color: rgba($white, 0.2);

            overflow-x: scroll;
          }

          &::placeholder {
            color: rgba($white, 0.5);
          }

          &--extra {
            padding: 1vw;
          }

          &--about {
            resize: none;
          }
        }

        &--extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 45%;

          &-city {
            width: 35%;
          }

          &-state {
            width: 25%;
          }

          &-zip {
            width: 35%;
          }
        }

        &-about {
          width: 100%;
        }
      }
    }

    &-photo {
      background-color: rgba($white, 0.1);
      border: none;
      border-radius: 8px;

      width: 10vw;
      height: 10vw;

      &-button {
        background-color: transparent;
        border: none;

        width: 100%;
        height: 100%;

        cursor: pointer;
      }
    }

    &-button {
      background: linear-gradient(90deg, $button-purple 0%, $button-blue 100%);

      border: none;
      border-radius: 8px;

      display: flex;

      padding: 1vw;

      &-text {
        color: $white;
        font-size: 1rem;

        margin-right: 1vw;
      }
    }
  }
}

.container-header {
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-bottom: 2vw;
}
