.container-radio-form {
  flex-direction: column;

  align-items: start !important;

  color: #fff;

  display: flex;
  gap: 20px;

  > div:nth-child(2) {
    display: flex;

    gap: 20px;

    > div {
      display: flex;
      gap: 10px;

      color: rgba(255, 255, 255, 1);
    }
  }
}

.container-checkbox > div:nth-child(1) > h1 {
  color: #fff;
  margin-bottom: 16px;
}

.content-checkbox {
  display: flex;
}

.checkbox-container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  width: max-content;
  color: rgba(255, 255, 255, 1);
}

.checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;

  cursor: pointer;
}

.checkbox-container input[type="checkbox"]:checked ~ .checkmark {
  border-color: rgba(167, 41, 245, 1);
}

.checkbox-container input[type="checkbox"]:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: rgba(167, 41, 245, 1);
  border-radius: 50%;
}

.container-buttons {
  > button:nth-child(2) {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    border-radius: 8px;
    display: flex;
    padding: 1vw;
    max-width: 245px;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}

.container-new-fields {
  display: flex;
  flex-direction: column;

  gap: 20px;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 20px;

    > div {
      width: 100%;

      display: flex;
      flex-direction: column;

      gap: 0.5vw;

      > label {
        color: #fff;

        width: 100%;

        padding: 0px 16px;
      }
    }
  }

  > button {
    width: max-content;
  }
}

.container-new-venue {
  display: flex;

  justify-content: space-between;

  > h1 {
    font-size: 18px;
    color: #fff;
  }

  > button {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    border-radius: 8px;
    display: flex;
    padding: 1vw;
    max-width: 245px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    color: #fff;
  }
}

.input-date {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 13px 16px;
  min-height: 38px;
  width: 100%;
}
