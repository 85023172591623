@use "../../styles/global" as *;
@use "../../styles//partials/variables" as *;

.events {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-buttons {
      display: flex;
      align-items: center;

      &-search {
        @extend .search;

        &-icon {
          @extend .search-icon;
        }

        &-input {
          @extend .search-input;

          color: #fff;
        }

        &-x {
          @extend .search-x;

          &-text {
            @extend .search-x-text;
          }
        }
      }

      &-colorful {
        @extend .colorful-button;
      }

      &-clear {
        @extend .clear-button;

        &-icon {
          padding-left: 1vw;
        }
      }
    }
  }

  &__tabs {
    width: 100%;
    border-bottom: 1px solid rgba($white, 0.1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &-link {
      width: 50%;
      text-decoration: none;

      padding: 1vw 0;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $white;

      &--active {
        border-bottom: 1px solid $general-purple;
      }
    }
  }

  &__info {
    &-upcoming {
      &-sorts {
        margin-bottom: 2vw;

        display: flex;

        &-button {
          @extend .sort-buttons;

          &-text {
            @extend .sort-buttons-text;
          }

          &-icon {
            padding-left: 0.8vw;
          }
        }
      }

      &-table {
        width: 100%;
        border-collapse: collapse;

        &-top {
          th {
            &:nth-child(1),
            &:nth-child(5) {
              width: 13%;
            }

            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(9) {
              width: 9.5%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(8),
            &:nth-child(11) {
              width: 8%;
            }

            &:nth-child(10) {
              width: 4%;
            }
          }

          &-headings {
            &-heading {
              @extend .table-headings;
            }
          }
        }

        &-info {
          &-info {
            border-bottom: $table-info-bottom;

            &-text {
              @extend .table-info;
            }

            &-icons {
              display: flex;
              margin-top: 0.5vw;
            }
          }
        }
      }
    }
  }
}

.queries {
  > div:nth-child(1) {
    > div:nth-child(1) {
      justify-content: end;
    }
  }
}
