@use "../../styles/global" as *;
@use "../../styles/partials/variables" as *;

.user-profile {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-left {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 2.5vw;
      }

      &-heading {
        @extend .page-headings;
      }
    }

    &-clear {
      @extend .clear-button;

      &-icon {
        padding-left: 1vw;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &-avatar {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      width: 5vw;
      height: 5vw;

      border-radius: 50%;

      color: $white;
    }

    &-writing {
      margin-left: 1vw;

      &-heading {
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 0.4vw;

        color: $white;
      }

      &-text {
        font-size: 0.85rem;
        font-weight: 200;

        margin-bottom: 0.2vw;

        color: $white;
      }
    }
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid rgba($white, 0.1);

    color: $white;

    margin-top: 40px;

    &-tab {
      width: 33%;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 1vw 0;

      cursor: pointer;

      border-bottom:  1px solid transparent;

      &--active {
        border-bottom-color:$general-purple;
      }
    }
  }
}

.card_favorites {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  min-width: 358px;
  height: auto;

  width: 350px;

  padding: 0 0 20px 0;

  > div:nth-child(1) {
    position: relative;
    padding: 5px;
    max-width: 100%;
    height: 123px;
    border-radius: 6px 6px 0px 0px;
    background-size: cover;
    background-position: center;

    margin: 5px;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 0px;
    gap: 10px;

    > h1 {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
    }
  }

  > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    padding: 5px 20px 0px;
    gap: 5px;
    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;

      display: flex;
      align-items: center;
      gap: 5px;
    }

    svg {
      color: rgba(167, 41, 245, 1);
      font-size: 16px;
    }
  }
}

.card_favorites-events {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  min-width: 358px;
  height: auto;

  width: 350px;

  filter: grayscale(100%);

  > div:nth-child(1) {
    position: relative;
    padding: 5px;
    max-width: 100%;
    height: 123px;
    border-radius: 6px 6px 0px 0px;
    background-size: cover;
    background-position: center;

    margin: 5px;

    &::before {
      content: "Past event";
      position: absolute;
      top: 5px;
      right: 5px;
      color: $white;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
    }

    > img {
      width: 350px;
      height: 123px;
      border-radius: 6px 6px 0px 0px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 15px;
    gap: 10px;

    svg {
      color: rgba(167, 41, 245, 1);
      font-size: 16px;
    }

    > h1 {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;

      min-height: 75px;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;

      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.container_content_user {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 16px;

  padding-top: 36px;

  width: 100%;

  > div {
    width: calc(33% - 6px);
  }
}

.content_following {
  display: flex;

  flex-direction: column;

  gap: 15px;

  width: 100% !important;

  > div {
    border-radius: 8px;
    padding: 8px 12px 8px 8px;
    background-color: rgba(255, 255, 255, 0.1);

    height: 64px;

    display: flex;
    align-items: center;
    gap: 10px;

    > h1 {
      color: $white;
      font-size: 16px;
    }

    > img {
      width: 48px;
      height: 48px;

      border-radius: 4px;
    }
  }
}
