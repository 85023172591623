.container-radio {
  > div:nth-child(1) {
    > h1 {
      color: #fff;

      margin-bottom: 16px;
    }
  }
}

.content-radio {
  display: flex;
  flex-direction: column;
}

.radio-container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;

  width: max-content;

  color: rgba(255, 255, 255, 1);
}

.radio-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.radio-container input[type="radio"]:checked ~ .checkmark {
  border-color: rgba(167, 41, 245, 1);
}

.radio-container input[type="radio"]:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: rgba(167, 41, 245, 1);
  border-radius: 50%;
}

.content-error {
  display: flex;
  align-items: center;

  gap: 10px;

  margin-bottom: 10px;

  > h1 {
    color: #fff;
  }
}
