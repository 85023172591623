#image {
  position: relative;

  border-radius: 12px;

  width: 172px;
  height: 172px;

  background-color: #ffffff1a;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  * {
    border-radius: inherit;
  }

  > img:nth-child(1) {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  > input {
    opacity: 0;

    z-index: 5;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  > button {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 6;

    background-color: rgba(0, 0, 0, 0.25);

    border: 0;
    outline: 0;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}
