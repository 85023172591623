@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.moderators {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-right {
      display: flex;
      align-items: center;

      &-search {
        @extend .search;

        &-icon {
          @extend .search-icon;
        }

        &-input {
          @extend .search-input;
        }

        &-x {
          @extend .search-x;

          &-text {
            @extend .search-x-text;
          }
        }
      }

      &-button {
        @extend .clear-button;

        &-icon {
          padding-left: 1vw;
        }
      }
    }
  }

  &__sorts {
    margin-bottom: 2vw;

    display: flex;

    &-button {
      @extend .sort-buttons;

      &-text {
        @extend .sort-buttons-text;
      }

      &-icon {
        padding-left: 0.8vw;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-top {
      th {
        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2),
        &:nth-child(4) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 26%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 4%;
        }
      }

      &-headings {
        &-heading {
          @extend .table-headings;
        }
      }
    }

    &-info {
      &-info {
        border-bottom: $table-info-bottom;

        &:hover {
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
        }

        &-text {
          @extend .table-info;

          &--status-box {
            display: flex;
            justify-content: center;
          }
        }

        &-status {
          &--general {
            border-radius: 40px;
            padding: 0.3vw 0.5vw;
            text-align: center;
            width: 80%;
          }

          &--pending {
            background-color: rgba($status-yellow, 0.1);
            color: $status-yellow;

            @extend .moderators__table-info-info-status--general !optional;
          }

          &--approved {
            background-color: rgba($status-green, 0.1);
            color: $status-green;

            @extend .moderators__table-info-info-status--general !optional;
          }

          &--denied {
            background-color: rgba($status-red, 0.1);
            color: $status-red;

            @extend .moderators__table-info-info-status--general !optional;
          }
        }
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.pagination {
  margin-top: 4vw;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.page-number {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;

  margin: 0 0.5vw;

  &-next {
    color: $white;
  }
  &-previous {
    color: $white;
  }
}

.active-number {
  color: $white;

  background-color: rgba(255, 255, 255, 0.1);
}
