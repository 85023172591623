.container-buttons {
  display: flex;
  gap: 20px;

  margin-top: 20px;
}

.container-edit-venue {
  display: flex;
  justify-content: space-between;

  > h1 {
    font-size: 1.3rem;
    font-weight: 400;
    color: #ffffff;
  }

  > div:nth-child(2) {
    display: flex;
    gap: 10px;

    > button {
      background-color: transparent;
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: 400;
      border-radius: 8px;
      border: 2px solid #ffffff;
      display: flex;
      justify-content: space-between;
      padding: 10px 12px 10px 12px;
      cursor: pointer;

      &:nth-child(2) {
        background-color: #8d1d1d;

        border: 2px solid #8d1d1d;
      }
    }
  }
}
