@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.sponsorship {
  @extend .general-tab;

  &__top {
    @extend .tab-top;

    &-heading {
      @extend .page-headings;
    }

    &-clear {
      @extend .clear-button;

      &-icon {
        padding-left: 1vw;
      }
    }
  }

  &__info {
    &-venues {
      &-sorts {
        margin-bottom: 2vw;

        display: flex;

        &-button {
          @extend .sort-buttons;

          &-text {
            @extend .sort-buttons-text;
          }

          &-icon {
            padding-left: 0.8vw;
          }
        }
      }

      &-table {
        width: 100%;
        border-collapse: collapse;

        &-top {
          th {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              width: 26%;
            }

            &:nth-child(4),
            &:nth-child(5) {
              width: 11%;
            }
          }

          &-headings {
            &-heading {
              @extend .table-headings;
            }
          }
        }
      }
    }
  }
}
