@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.notifs {
  padding: 0 2vw;

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 45%;

    padding: 1vw 0;

    color: $white;

    &-text {
      font-size: 1.1rem;
      font-weight: 200;
    }
  }
}
