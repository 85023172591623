.spotlight-event {
  display: flex;
  flex-direction: column;

  gap: 32px;

  width: 100%;
  > div {
    display: flex;
    gap: 20px;
    height: auto;

    > div {
      width: 100%;
    }
  }
}

.container-button {
  > button {
    background: linear-gradient(90deg, #7229f5 0%, #0a3fd6 100%);
    border: none;
    border-radius: 8px;
    display: flex;
    padding: 1vw;
    max-width: 100%;
    align-items: center;
    justify-content: center;

    gap: 10px;

    color: #ffffff;
    font-size: 1rem;
    margin-right: 1vw;

    cursor: pointer;
  }
}

.container-radio {
  display: flex;
  flex-direction: column;

  h3 {
    color: #fff;
  }

  > label {
    display: flex;
    gap: 10px;

    > input {
      width: 24px;
      height: 24px;
    }
  }
}
