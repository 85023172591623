@use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.venue-info {
  &__form {
    width: 100%;

    padding: 2vw 1vw;

    // display: flex;

    color: $white;

    &--left {
      width: 100%;
    }

    &-group {
      display: flex;
      align-items: center;

      gap: 20px;

      &-field {
        display: flex;
        flex-direction: column;

        width: 100%;

        margin-bottom: 2vw;

        color: $white;

        &-label {
          display: flex;
          align-items: center;
          gap: 10px;

          padding: 0px 16px;

          margin-bottom: 0.5vw;
        }

        &-input {
          background-color: rgba($white, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;

          height: 44px;
        }
        &-select {
          > div {
            border: none;

            background-color: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(4px);
            border-radius: 8px;
            width: 100%;

            box-shadow: none;

            span {
              display: none;
            }
            div {
              color: rgba(255, 255, 255, 1);
              background-color: transparent;

              cursor: pointer;
            }
          }
        }
      }

      &-about {
        display: flex;
        flex-direction: column;

        width: 100%;

        margin-bottom: 2vw;

        &-input {
          background-color: rgba($grey, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw;

          resize: none;

          // height: 8vw;
        }
      }

      &-images {
        padding: 20px 0;

        &-input {
          background-color: rgba($white, 0.1);
          border: none;
          border-radius: 8px;

          width: 10vw;
          height: 10vw;

          &-button {
            background-color: transparent;
            border: none;

            width: 100%;
            height: 100%;

            cursor: pointer;
          }
        }
      }
    }

    &-submit {
      @extend .colorful-button;

      &-text {
        margin-right: 1vw;
      }
    }
  }
}

.tags-container {
  display: flex;
  flex-direction: column;
}

.tag-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  > label {
    display: flex;
    align-items: center;

    color: rgba(255, 255, 255, 1);
  }
}

.tag-row label {
  flex: 1;
}

.tag-row input[type="checkbox"] {
  margin-right: 5px;

  width: 24px;
  height: 24px;
}

.container-funds {
  display: flex;
  flex-direction: column;

  gap: 24px;

  margin-bottom: 48px;

  > h1 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }

  .container-input {
    > input {
      width: 50%;
    }
  }
}
