//background colors
$background-black: #020204;
$background-purple: #2C156D;
$background-blue: #151B6D;
$background-darkblue: #170D36;

//general
$white: #FFFFFF;
$grey: #F6F6FA;

//buttons
$button-blue: #0A3fD6;
$button-purple: #7229F5;
$button-sort-hover: rgba($white, 0.2);
$button-sort-active: rgba($background-black, 0.1);

//status
$status-yellow: #F1C40F;
$status-red: #E74C3C;
$status-green: #2ECC71;

//other stuff
$table-info-bottom: 1px solid rgba($white, .05);
$general-purple: #A729F5;