@use "../../styles/partials/variables" as *;

.sidebar {
  height: auto;
  max-width: 100%;

  position: fixed;
  left: 0; top: 0; bottom: 0;

  background: linear-gradient(to left, #0D0024 50%, #010202 100%, #2C204E 100%);

  color: $white;

  padding-top: calc(var(--topbar-height) + 0.5rem);

  overflow-y: auto;

  width: var(--sidebar-width);

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border: 2px solid transparent;

    padding: 16px 12px 16px 32px;

    gap: 20px;

    &:hover {
      border: 2px solid $white;
      border-radius: 8px;
    }

    &-left {
      display: flex;
      align-items: center;

      gap: 14px;

      &-icon {
        width: 24px;
        height: 24px;
      }

      &-writing {
        font-weight: 500;

        color: $white;

        font-size: 14px;

        line-height: 16.94px;
      }
    }
  }
}

.active {
  background: var(--primary-color);

  border-radius: 8px;
}
