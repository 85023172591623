#dropdown {
  position: relative;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 16px;

  width: 100px;

  button {
    background-color: transparent;
    border: 0;
    outline: 0;
  }

  > .dropdown-controller {
    height: 100%;

    > span {
      font-size: 14px;
      font-weight: 700;

      color: #ffffff;
    }
  }

  > .dropdown-content {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;

    z-index: 50;

    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    border-radius: 8px;
    padding: 16px 0;

    > button {
      color: #fff;
      font-size: 14px;
      font-weight: 400;

      width: 100%;

      display: block;

      text-align: start;

      padding: 8px 16px;

      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

#container {
  display: flex;
  align-items: center;
  gap: 10px;

  height: 44px;

  background-color: #ffffff1a;

  border-radius: 8px;

  padding-right: 16px;

  width: 100%;

  min-height: 54px;

  > input {
    background-color: transparent;

    font-size: 14px;
    font-weight: 700;
    color: #fff;

    border: 0;
    outline: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  > span {
    font-size: 14px;

    color: #ffffff;

    opacity: 0.5;
  }
}
