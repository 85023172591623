  @use "../../styles/partials/variables" as *;
@use "../../styles/global" as *;

.add-user {
  @extend .general-tab;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 2vw;

    &-icon {
      width: 2vw;
      height: 2vw;
      min-width: 40px;
    min-height: 40px;

      margin-right: 1.5vw;
    }

    &-heading {
      @extend .page-headings;
    }
  }

  &__form {
    width: 100%;

    display: flex;

    &--left {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 32px;


      > div {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 767px) {
          flex-wrap: wrap;
        }

        > div {
          width: 100%;
        }
      }
    }

    &--right {
      padding: 2vw 0 0 6vw;
    }

    &-first {
      display: flex;

      gap: 20px;

      align-items: center;

      &-field {
        display: flex;
        flex-direction: column;

        width: 45%;

        margin-bottom: 2vw;

        color: $white;

        &-label {
          padding: 0.5vw 2vw;
        }

        &-input {
          background-color: rgba($white, 0.1);
          color: $white;

          outline: none;
          border: none;
          border-radius: 8px;

          padding: 1vw 2vw;

          &--special {
            display: flex;
            justify-content: space-between;
            padding-right: 1vw;
          }

          &-text {
            font-size: 0.8rem;
            font-weight: 300;

            color: rgba($white, 0.2);
          }

          &::placeholder {
            color: rgba($white, 0.5);
          }

          &:focus {
            border: 1px solid $general-purple;
          }

          &-extra {
            border: none;
            outline: none;
            background-color: transparent;
          }

          &--invalid {
            border: 1px solid $status-red;
          }
        }
      }
    }

    &-photo {
      margin-bottom: 40px;

      display: flex;

      gap: 20px;

      &-button {
        background-color: transparent;
        border: none;

        width: 100%;
        height: 100%;

        cursor: pointer;
      }
    }

    &-button {
      background: linear-gradient(90deg, $button-purple 0%, $button-blue 100%);

      border: none;
      border-radius: 8px;

      display: flex;

      padding: 1vw;

      cursor: pointer;
      width: max-content;

      &-text {
        color: $white;
        font-size: 1rem;

        margin-right: 1vw;
      }
    }
  }
}
