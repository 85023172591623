@use "../../styles/global" as *;

.container-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  label {
    color: #fff;
  }

  > input {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 16px;
    // min-height: 38px;
    min-height: 54px;
    font-size: 14px;
  }

  > div:nth-child(1) {
    display: flex;
    padding: 0px 16px;
    align-items: center;

    gap: 16px;

    > label {
      color: #ffffff;
      font-size: 14px;
    }
  }
}

.error-message {
  color: red;

  font-size: 0.8em;
}
