.container-formdate {
  display: flex;
  flex-direction: column;

  gap: 0.5vw;

  > label {
    padding: 0px 16px;
    color: #fff;
    font-size: 14px;
  }

  > input {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 13px 16px;
    min-height: 38px;
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 13px 16px;
  min-height: 54px;

  width: 100%;
}

.react-datepicker-popper .react-datepicker__triangle::before {
  border-bottom-color: #ccc;
}

.react-datepicker {
  font-size: 16px;
}

.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: none;
}

.react-datepicker__navigation {
  top: 15px;
  border: none;
  background: none;
}

.react-datepicker__day {
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin: 2px;
  border-radius: 50%;
}

.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__day:hover {
  background-color: #f0f0f0;
  color: #333;
}

.react-datepicker-popper {
  background-color: red !important;

  z-index: 9999999999 !important; 
}
